<script setup lang="ts">
/**
 * SelectableCard Component
 *
 * This component is a styled card component that can be selected.
 * Note: this component is ment to be used in combinatin with a SelectableCardGroup.
 *
 * Id must be defined in order for the SelectableCardGroup to be able to manage the
 * selection state between the cards.
 *
 */

const props = withDefaults(
  defineProps<{
    id: string;
    disabled?: boolean;
    onClick: () => void;
  }>(),
  {
    disabled: false,
  },
);

// Injecting selection state and function from the parent
const selectedCardId = inject<Ref<string | undefined>>(
  "selectedCardId",
  ref(undefined),
);
const updateSelectedCard =
  inject<(id: string | undefined) => void>("updateSelectedCard");

const isSelected = computed(() => {
  return selectedCardId.value !== null && selectedCardId.value === props.id;
});

function setSelected() {
  if (props.disabled) return;
  if (updateSelectedCard !== undefined && selectedCardId !== undefined) {
    updateSelectedCard(props.id);
    props.onClick();
  } else {
    console.error(
      "'SharedSelectableCard' must be wrapped by a 'SharedSelectableCardGroup'",
    );
  }
}
</script>

<template>
  <div
    :class="[
      isSelected ? 'b-2' : 'b-1',
      props.disabled
        ? 'border-scheppach-neutral-300 opacity-40'
        : 'border-scheppach-primary-500',
    ]"
    class="relative b rounded flex-grow cursor-pointer"
    @click="setSelected"
  >
    <span
      :class="[
        isSelected ? '' : 'hidden',
        props.disabled
          ? 'bg-scheppach-neutral-300'
          : 'bg-scheppach-primary-500',
      ]"
      class="w-6 h-6 rounded-tr rounded-bl absolute -top-[1px] -right-[1px] text-white text-center"
      >✔</span
    >
    <slot></slot>
  </div>
</template>
